import React from "react";
import Alert from "../util/modal/Alert";
import Menu from "./Menu";
import {useSelector} from "react-redux";
import isNull from "../../mixin/isNull";
import {useParams} from "react-router";

export default function ({title, orderId, progressPercent}) {
    const {token} = useParams();
    const order = useSelector(state => state.app.order);
    const now = new Date();

    const handleOpenNewVersion = () => {
        window.open("https://artisan.fairfair.com/private/intervention/emergency/" + token);
    }

    return (
        <div className={'grid grid-cols-1'}>
            <Alert/>
            <div className={'flex justify-between mt-2 mx-2'}>
                {/*<img src={logo} style={{width: '50px', height: '50px'}}/>*/}

                <Menu/>
            </div>
            {
                now.getMonth() > 4 && now.getMonth() < 9 && false
                    ? (
                        <div className="flex text-left bg-orange-600 text-white text-xs m-2 p-2 rounded-md">
                            Pensez à déclarer vos vacances sur votre espace
                            <div className="flex justify-center items-center ml-2">🏖️</div>
                        </div>
                    )
                    : null
            }

            <div className="flex text-center bg-red-600 text-white m-2 p-2 rounded-md" onClick={handleOpenNewVersion}>
                L'application évolue, cliquez ici pour accéder à la nouvelle version. À partir du 2 décembre, la
                version actuelle ne sera plus disponible.
            </div>

            {
                !isNull(order) && !isNull(order.admin_phone)
                    ? (
                        <a href={`tel:${order.admin_phone}`}>
                            <button
                                id="preferred-interlocutor-button"
                                className="text-white flex bg-orange-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full">
                                <span className="text-sm">
                                        Contacter mon interlocuteur privilégié
                                </span>
                            </button>
                        </a>
                    )
                    : null
            }
            <hr className={'my-2 border bg-blue-0'}/>

            <div className={'my-3 mx-2'}>
                <div className={'h-3 flex rounded-full bg-blue-0 shadow-sm'}>
                    <div style={{width: progressPercent + '%'}} className={'flex bg-blue-1 h-3 rounded-full'}/>
                </div>
            </div>

            <div className={'flex justify-between mx-2'}>
                <h1 className="text-lg font-bold uppercase text-gray-600">
                    {title}
                </h1>
                <div>
                    <span
                        className="rounded-full bg-gray-600 text-white px-2 pt-1 pb-1 text-center font-bold whitespace-nowrap">
                        <span className="my-auto">
                            REF {orderId}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
}
