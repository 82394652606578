export default {
    isQuotationStandardized(prescriberId) {
        return true;
    },
    isRefuseRenovation(prescriberId) {
        return prescriberId !== 6 && prescriberId !== 7;
    },
    getShiftingPrice(prescriberId, defaultPrice) {
        if (prescriberId == 11) {
            return 60;
        }

        return defaultPrice;
    },
    getMainWorkPrice(prescriberId, defaultPrice) {
        if (prescriberId == 11) {
            return 65;
        }

        return defaultPrice;
    },
    
    allowSecondMission(prescriberId) {
        return prescriberId !== 24;
    }
}
