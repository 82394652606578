import React from "react";
import mixin from "../../../../mixin/mixin";
import specificity from "../../../../specificity/specificity";
import {useSelector} from "react-redux";

export default function ({quotationItems, requestActionController}) {
    const order = useSelector(state => state.app.order);
    const quotationItemsDefaultIds = quotationItems.map(item => item.default_item_id);

    return (
        <div key={quotationItems.length}>
            {
                specificity.isQuotationStandardized(order.prescriber_id)
                    ? (
                        <div>
                            <h4 className={'text-md inline mb-5'}>Des fournitures sont-elles nécessaires ? <br/>
                                Si oui, sélectionnez dans le catalogue :</h4>

                            <br/>
                        </div>
                    )
                    : (
                        <h4 className={'text-md inline mb-5'}>Des fournitures sont-elles nécessaires afin de finaliser cette
                            intervention ? <br/>
                            Si oui, merci d'établir votre devis au travers de l'application et d'attendre la validation de
                            notre plateau pour intervenir</h4>
                    )
            }

            <div className={'gap-y-2 mb-2'}>
                {
                    displayMessage(quotationItemsDefaultIds)
                }
                {
                    quotationItems.map((quotationItem, index) => {
                        return quotationItem.default_item_id === null ?
                            (
                                <div className={'flex items-center mt-2'} key={quotationItem.reactId}>
                                    <div className={'flex-1 bg-gray-100 shadow p-2'} id={`quotation-item-${index}`}
                                         onClick={() => requestActionController('UPDATE', quotationItem.reactId)}>
                                        <div className={'grid grid-cols-4'}>
                                            <div className="col-span-2 text-base">{quotationItem.name}</div>
                                            <div className="flex justify-center text-base">
                                                <span
                                                    className={'font-bold'}>{mixin.priceFormatter(quotationItem.price)}</span>€
                                            </div>
                                            <div className="text-right text-base">x {quotationItem.quantity}</div>
                                        </div>
                                    </div>
                                    <div className={'ml-2'}>
                                        <button
                                            className={`bg-${quotationItem.default_item_id !== null ? 'gray-500' : 'red-500'} p-2 text-white`}
                                            disabled={quotationItem.default_item_id !== null}
                                            onClick={() => requestActionController('REMOVE', quotationItem.reactId)}>
                                            <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                                 viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )
                            : null
                    })
                }
            </div>
        </div>
    );
}

const displayMessage = (quotationItemsDefaultIds) => {
    return (
        <div className="flex items-center mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24"
                 stroke="red">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
            <p className="ml-4 text-red-500">
                {
                    quotationItemsDefaultIds.includes(50)
                        ? 'Le premier déplacement, heure de main d\'oeuvre et supplément nuit/week-end sont déjà inclus.'
                        : 'Le premier déplacement et main d\'oeuvre sont déjà inclus.'
                }
            </p>
        </div>
    )
}
