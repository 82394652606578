import Info from "../../../../util/modal/Info";
import React, {useEffect, useRef, useState} from "react";
import useStore from "../../../../../use/useStore";
import {useSelector} from "react-redux";
import isNull from "../../../../../mixin/isNull";
import NextStepButton from "../../NextStepButton";
import useApi from "../../../../../api/useApi";
import useQualityInspection from "../../../../../api/useQualityInspection";
import Spinner from "../../../../util/loader/Spinner";

const QUOTATION_VALIDATION = 0;
const PRESCRIBER_QUOTATION_VALIDATION = 1;

export default function () {
    const api = useApi();
    const qualityInspectionApi = useQualityInspection();
    const store = useStore();
    const order = useSelector(state => state.app.order);
    const interval = useRef();
    const [waitingFor, setWaitingFor] = useState(QUOTATION_VALIDATION);
    const [autoValidInProgress, setAutoValidInProgress] = useState(true);

    useEffect(() => {
        interval.current = setInterval(async () => {
            const isValidated = await api.qualityInspection.isValidated();

            if (isValidated) {
                await store.init();
                clearInterval(interval.current)
                setWaitingFor(PRESCRIBER_QUOTATION_VALIDATION);

                interval.current = setInterval(async () => {
                    const isWaiting = await api.qualityInspection.isWaitingPrescriberValidation();

                    if (!isWaiting) {
                        await store.init();
                        clearInterval(interval.current)
                    }
                }, 3000);
            }
        }, 3000);

        return () => clearInterval(interval.current);
    }, []);

    useEffect(() => {
        setTimeout(async () => {
            await qualityInspectionApi.autoValidQuotation();

            await store.init();

            setAutoValidInProgress(false);
        }, 3000);
    }, []);

    return (
        <div>
            <Info/>

            <div className={'my-5 p-2 flex-column mt-10'}>
                <div className={'flex flex-col my-2'}>
                    {
                        autoValidInProgress
                            ? (
                                <div className="flex justify-center items-center mt-10">
                                    <div>
                                        <Spinner color={"text-blue-1"} size={6}/>
                                    </div>
                                    <div className="text-blue-1">
                                        Analyse de votre devis en cours
                                    </div>
                                </div>
                            )
                            : (
                                <>
                                    {
                                        !isNull(order) /**&& !isNull(order.admin_phone)*/ && waitingFor === QUOTATION_VALIDATION && (new Date()).getHours() >= 9 && (new Date()).getHours() < 18
                                            ? (
                                                <a href={`tel:${process.env.REACT_APP_QUOTATION_VALIDATION_PHONE}`}>
                                                    <button
                                                        className="text-white flex bg-red-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full"
                                                        id="quotation-validation-assistance-phone-button"
                                                    >
                                                        <span className="text-sm">
                                                                Cliquez ici pour nous appeler et valider votre devis
                                                        </span>
                                                    </button>
                                                </a>
                                            )
                                            : (
                                                <a href={`tel:${process.env.REACT_APP_ASSISTANCE_PHONE}`}>
                                                    <button
                                                        className="text-white flex bg-red-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full"
                                                        id="quotation-validation-assistance-phone-button"
                                                    >
                                                        <span className="text-sm">
                                                                Cliquez ici pour nous appeler et valider votre devis
                                                        </span>
                                                    </button>
                                                </a>
                                            )
                                    }
                                </>
                            )
                    }


                    {
                        waitingFor === PRESCRIBER_QUOTATION_VALIDATION
                            ? (
                                <div
                                    className="bg-blue-1 w-full h-16 text-center text-white flex items-center justify-center mt-20">
                                    Nous attendons la validation de l'assistance, veuillez patienter.
                                </div>
                            )
                            : null
                    }
                </div>
            </div>

            <NextStepButton loading={true} style="fixed"/>
        </div>
    );
}