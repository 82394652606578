import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openInfo} from "../../../store/util";
import Info from "../../util/modal/Info";
import NextStepButton from "./NextStepButton";
import CardPayment from "./work/CardPayment";
import CheckPayment from "./work/CheckPayment";
import useStore from "../../../use/useStore";
import useAlert from "../../../use/useAlert";
import enums from "../../../enum/enums";
import isNull from "../../../mixin/isNull";
import useApi from "../../../api/useApi";

export default function StepWork() {
    const api = useApi();
    const store = useStore();
    const alert = useAlert();
    const dispatch = useDispatch();
    const qualityInspection = useSelector(state => state.app.qualityInspection);
    const intervention = useSelector(state => state.app.intervention);
    const completedWorksInput = useRef();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showPaymentOptions, setShowPaymentOptions] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isClientPayed, setClientPayed] = useState(false);

    useEffect(async () => {
        if (intervention === null || intervention === undefined) {
            return;
        }
        if (intervention.billing_mode !== 1 || intervention.client_price === 0 || intervention.client_price === null) {
            setClientPayed(true);
        }
        if (intervention.billing_mode === 1 && intervention.client_price > 0) {
            const response = await api.intervention.isClientInvoicePaid();
            if (response.is_payed) {
                setClientPayed(true);
            }
        }
    }, [intervention]);

    useEffect(() => {
        if (isNull(qualityInspection) || qualityInspection.current_sub_step !== enums.qualityInspection.SUB_STEP_DEFAULT) return;

        dispatch(openInfo({
            title: 'Vous pouvez intervenir'
        }));
    }, [qualityInspection]);

    const abort = () => {
        setPaymentMethod(null);
        setShowPaymentOptions(true);
    };

    const paymentSucceeded = () => {
        setClientPayed(true);
    };

    const nextStep = async () => {

        if (completedWorksInput.current.value === "") {
            alert.launch("Merci de préciser les travaux effectués", "warning");
            return;
        }

        if (completedWorksInput.current.value.length < 20) {
            alert.launch("Merci d'apporter plus de précision sur votre intervention", "warning");
            return;
        }

        setButtonLoading(true);

        const isSuccess = await api.qualityInspection.nextStep({
            quality_inspection: {
                completed_works: completedWorksInput.current.value,
                current_step: enums.qualityInspection.STEP_WORK
            }
        });

        if (isSuccess) {
            await store.init("qualityInspection");
            return;
        }

        alert.launch("Une erreur s'est produite", "error");
        setButtonLoading(false);
    }

    if (!isClientPayed) {
        return showPaymentOptions
            ? (
                <div className="flex flex-col">
                    <h3 className="px-2 my-6 w-full text-red-600 text-center py-6">
                        Règlement du reste à charge sociétaire
                    </h3>
                    <div className="flex flex-col justify-around items-center">
                        <button
                            className="bg-blue-1 text-white text-center h-16 mx-2 mb-10 w-full flex items-center justify-center align-middle"
                            onClick={() => {
                                setPaymentMethod("card");
                                setShowPaymentOptions(false);
                            }}
                        >
                            Payer par carte bancaire
                        </button>
                        <button
                            className="bg-blue-1 text-white text-center h-16 mx-2 w-full flex items-center justify-center align-middle"
                            onClick={() => {
                                setPaymentMethod("check");
                                setShowPaymentOptions(false);
                            }}
                        >
                            Payer par chèque ou espèces
                        </button>
                    </div>
                </div>
            )
            :
            paymentMethod === "card"
                ? <CardPayment abort={abort} paymentSucceeded={paymentSucceeded}/>
                : <CheckPayment abort={abort} paymentSucceeded={paymentSucceeded}/>
    }

    return (
        <div>
            <div>
                <div className={'mt-10'}>
                    <Info/>
                </div>

                <div className={'my-5 p-2 flex-column'}>
                    <textarea
                        id="completed-works-input"
                        ref={completedWorksInput}
                        placeholder="Décrivez avec précision les travaux effectués ..."
                        className="w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2 py-32"
                    />
                </div>

                <NextStepButton title={'Etape suivante'} callback={() => nextStep()}
                                loading={buttonLoading}/>
            </div>
        </div>
    );
}

