import {Fragment, useEffect, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useDispatch, useSelector} from "react-redux";
import {closeConfirmPassword} from "../../../store/util";

function ConfirmPassword() {
    const cancelButtonRef = useRef(null);
    const dispatch = useDispatch();
    const input = useRef();

    const open = useSelector(state => state.util.modal.confirmPassword.open);
    const title = useSelector(state => state.util.modal.confirmPassword.title);
    const description = useSelector(state => state.util.modal.confirmPassword.description);
    const callback = useSelector(state => state.util.modal.confirmPassword.callback);
    const password = useSelector(state => state.util.modal.confirmPassword.password);
    const sentence = useSelector(state => state.util.modal.confirmPassword.sentence);

    useEffect(() => {
        if (!open) {
            return;
        }

        setTimeout(() => {
            input.current.focus();
        }, 100);
    }, [open]);

    const abort = () => {
        dispatch(closeConfirmPassword());

        if (callback !== null && callback.length > 0) {
            for (let i = 0; i < callback.length; i++) {
                callback[i](false);
            }
        }
    }

    const next = () => {
        dispatch(closeConfirmPassword());

        if (callback !== null && callback.length > 0) {
            for (let i = 0; i < callback.length; i++) {
                callback[i](true);
            }
        }
    }

    const handleChange = (e) => {
        if (e.target.value === password) {
            next();
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className={'fixed z-10 inset-0 overflow-y-auto'} initialFocus={cancelButtonRef}
                    onClose={() => abort()}>
                <div
                    className={'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'}/>
                    </Transition.Child>

                    <span className={'hidden sm:inline-block sm:align-middle sm:h-screen'} aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'}>
                            <div className={'bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'}>
                                <div className={'sm:flex sm:items-start'}>
                                    <div
                                        className={'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'}>
                                        <ExclamationIcon className={'h-6 w-6 text-red-600'} aria-hidden="true"/>
                                    </div>
                                    <div className={'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'}>
                                        <Dialog.Title as="h3"
                                                      className={'text-lg leading-6 font-medium text-gray-900'}>
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {description}
                                            </p>

                                        </div>
                                        <p>{sentence}</p>
                                        <div className="flex my-1">
                                            <input
                                                ref={input}
                                                type="number"
                                                onChange={e => handleChange(e)}
                                                className="m-0 rounded-md"
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => abort()}
                                    ref={cancelButtonRef}
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    );
}

export default ConfirmPassword;
