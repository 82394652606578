import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const quotationInterval = useRef();
    const pictureInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(quotationInterval.current);
            clearInterval(pictureInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_CLIENT_SIGNATURE) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-6.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-6.2.mp3";
            window.sound.play();
        }, 10000);

        launchQuotation();
        launchPicture();
    }

    const launchQuotation = () => {
        setTimeout(() => {
            quotationInterval.current = setInterval(() => {
                const quotation = document.getElementById("client-price-details");

                quotation.focus();

                if (quotation.style.background === "") {
                    quotation.style.background = "yellow";
                } else {
                    quotation.style.background = "";
                }
            }, 500)
        }, 4000);

        setTimeout(() => {
            clearInterval(quotationInterval.current);

            const quotation = document.getElementById("client-price-details");
            quotation.style.background = "";
        }, 9000)
    }

    const launchPicture = () => {
        setTimeout(() => {
            quotationInterval.current = setInterval(() => {
                const clientPriceDetails = document.getElementById("requested-document-3");

                clientPriceDetails.focus();

                if (clientPriceDetails.style.background === "") {
                    clientPriceDetails.style.background = "yellow";
                } else {
                    clientPriceDetails.style.background = "";
                }
            }, 500)
        }, 12000);

        setTimeout(() => {
            clearInterval(quotationInterval.current);

            const quotation = document.getElementById("requested-document-3");
            quotation.style.background = "";
            setStepCompleted(true);
        }, 16000);
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}