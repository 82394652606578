import useApi from "../../../../../api/useApi";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import enums from "../../../../../enum/enums";
import {openAlert} from "../../../../../store/util";
import mixin from "../../../../../mixin/mixin";

export default function ({onUploaded}) {
    const api = useApi();
    const dispatch = useDispatch();
    const [requestedDocuments, setRequestedDocuments] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);


    useEffect(() => {
        api.requestedDocument.getAllRequestedDocumentByToken()
            .then(response => {
                const result = response.filter((requestedDocument) => requestedDocument.status === enums.requestedDocument.STATUS_WAITING_FOR_PROVIDE);
                setRequestedDocuments(result);
            });
    }, []);

    useEffect(async () => {
        if (uploadedFile === null) {
            return;
        }

        const isSuccess = await api.requestedDocument.patchDocument(uploadedFile.id, uploadedFile.content, uploadedFile['content-type']);

        if (isSuccess) {
            dispatch(openAlert({
                title: 'Document fourni',
                type: 'success'
            }));

            onUploaded();
        } else {
            dispatch(openAlert({
                title: 'Une erreur s\'est produite',
                type: 'error'
            }));
        }

        setUploadedFile(null);

    }, [uploadedFile]);

    const imageUploaded = async (file, id) => {
        let document = null;

        if (file === null || file === undefined) {
            dispatch(openAlert({
                title: 'Aucune image',
                type: 'warning'
            }));

            return;
        }

        if (file.type.startsWith("image")) {
            const data = await mixin.compressFile(file, {
                size: 1,
                quality: .5,
                rotate: false
            });

            let contentType = file.type;
            let content = data[0].data;

            setUploadedFile({
                ...{
                    content: content,
                    "content-type": contentType,
                    id: id
                }
            });

        } else {
            const reader = new FileReader();

            reader.onload = ((f) => (e) => {
                let contentType = file.type;
                let content = e.target.result.replace("data:" + contentType + ";base64,", "");

                document = {
                    "content": content,
                    "content-type": contentType,
                    id: id
                };

                setUploadedFile({...document})
            })(file);

            reader.readAsDataURL(file);
        }

    }

    if (requestedDocuments.length === 0) return null;

    return (
        <div>
            <div>
                <span className="font-bold text-red-600">Étape 1 : </span>
                <span
                    className="text-red-600">Faites un devis papier, en recopiant à la lettre les éléments ci-dessus</span>
                <div className="italic text-sm mt-1">
                    "Tout manquement aux obligations d'information précontractuelle mentionnées aux 1° à 4° et 6° de
                    l'article L. 111-1 et aux articles L. 111-2 et L. 111-3 est passible d'une amende administrative
                    dont le montant ne peut excéder 3 000 euros pour une personne physique et 15 000 euros pour une
                    personne morale.
                    Cette amende est prononcée dans les conditions prévues au chapitre II du titre II du livre V."
                </div>
            </div>
            <div className="text-red-600 mt-5">
                <span className="font-bold">Étape 2 : </span>
                <span>Faites signer le devis papier par le sociétaire</span>
            </div>

            {
                requestedDocuments.map((item) => {
                    if (!item.name.includes("Attestation")) return null;

                    return (
                        <div className={'my-7'} key={item.id}>
                            <button
                                id={`requested-document-${item.id}`}
                                className="bg-blue-600 text-white p-3 flex justify-center w-full cursor-pointer"
                                onClick={e => {
                                    document.getElementById(`input-document-${item.id}`).click();
                                }}
                            >
                                <input
                                    id={`input-document-${item.id}`}
                                    className="custom-file-input hidden"
                                    type="file"
                                    name="document"
                                    onChange={(event) => imageUploaded(event.target.files[0], item.id)}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6 mx-1'} fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"/>
                                </svg>
                                <label>
                                    Prenez votre devis en photo
                                </label>
                            </button>
                        </div>
                    )
                })
            }
        </div>
    )
}