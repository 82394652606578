import axios from "axios";
import {update} from "../store/demo";
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import enums from "../enum/enums";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();
    const dispatch = useDispatch();

    return {
        async getQualityInspection() {
            if (token === "demo") return null;

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async isValidated() {
            if (token === "demo") {
                return false;
            }
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/validated/by/token/${token}`);
                return response.data.quotation_validate;
            } catch (e) {
                return false;
            }
        },
        async isPayed() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/is_payed`);
                return response.data.is_payed;
            } catch (e) {
                return false;
            }
        },
        async skipCertificateSignature() {
            if (token === "demo") {
                dispatch(update({
                    property: "current_step",
                    value: enums.qualityInspection.STEP_CLIENT_SIGNATURE
                }));

                dispatch(update({
                    property: "current_sub_step",
                    value: enums.qualityInspection.SUB_STEP_DEFAULT
                }));
                return true;
            }

            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/skip_certificate_signature`, null);
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async autoValidQuotation() {
            if (token === "demo") {
                return true;
            }

            try {
                await axios.post(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/validation`, null);
                return true;
            } catch (e) {
                return false;
            }
        },
        async getClientPriceInvoice() {
            if (token === "demo") {
                return [
                    {
                        name: "Déplacement (Pris en charge par votre assistance)",
                        price: 0,
                        quantity: 1
                    },
                    {
                        name: "Main d'oeuvre (Pris en charge par votre assistance)",
                        price: 0,
                        quantity: 1
                    },
                    {
                        name: "Serrure",
                        price: 100,
                        quantity: 1
                    },
                    {
                        name: "Porte",
                        price: 1130,
                        quantity: 1
                    },
                    {
                        name: "Déplacement supplémentaire",
                        price: 70,
                        quantity: 1
                    }
                ];
            }
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/client_price_invoice/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async nextStep(payload) {
            if (token === "demo") {
                for (const key of Object.keys(payload)) {
                    for (const key2 of Object.keys(payload[key])) {
                        dispatch(update({
                            property: key2,
                            value: payload[key][key2]
                        }))
                    }
                }

                if (payload.quality_inspection.current_step === enums.qualityInspection.STEP_QUOTATION) {
                    dispatch(update({
                        property: "current_sub_step",
                        value: enums.qualityInspection.SUB_STEP_ALL_TO_VALIDATE
                    }));

                    setTimeout(() => {
                        dispatch(update({
                            property: "quotation_validate",
                            value: true
                        }));
                    }, 5000);
                }


                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/step`, payload);
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchDeliveredComment(deliveredComment) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/delivered_comment`, {
                    quality_inspection: {
                        delivered_comment: deliveredComment
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchDeliveredWithoutReservation(deliveredWithoutReservation) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/delivered_without_reservation`, {
                    quality_inspection: {
                        delivered_without_reservation: deliveredWithoutReservation
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchArrivedAt(arrivedAt) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/arrived_at`, {
                    quality_inspection: {
                        arrived_at: arrivedAt
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchLeftAt(leftAt) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/left_at`, {
                    quality_inspection: {
                        left_at: leftAt
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchRequestNextOrder(requestNextOrder, orderComment) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/request_next_order`, {
                    quality_inspection: {
                        request_next_order: requestNextOrder
                    },
                    order: {
                        order_comment: orderComment
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async isWaitingPrescriberValidation() {
            if (token === "demo") {
                return false;
            }

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/waiting_prescriber_validation/by/token/${token}`);
                return response.data.waiting_prescriber_validation;
            } catch (e) {
                return false;
            }
        },
        async patchClientNotReply(clientNotReply) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/client_not_reply`, {
                    quality_inspection: {
                        client_not_reply: clientNotReply
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async patchClientContactedByCompany(clientContactedByCompany) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quality_inspections/by/token/${token}/client_contacted_by_company`, {
                    quality_inspection: {
                        client_contacted_by_company: clientContactedByCompany
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
    };
}

