import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import isNull from "../../../mixin/isNull";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";

/**
 * Voice provided by https://ttsmaker.com/
 * @return {JSX.Element|null}
 */
export default function () {
    const {token} = useParams();
    const [currentStep, setCurrentStep] = useState(null);

    useEffect(() => {
        window.sound = new Audio();

        nextStep(0);
    }, []);

    const nextStep = (next) => {
        setCurrentStep(DEMO_STEP.find(step => step.step === next));
    }

    const DEMO_STEP = [
        {
            step: 0,
            component: <Step1 nextStep={() => nextStep(1)}/>
        },
        {
            step: 1,
            component: <Step2 nextStep={() => nextStep(2)}/>
        },
        {
            step: 2,
            component: <Step3 nextStep={() => nextStep(3)}/>
        },
        {
            step: 3,
            component: <Step4 nextStep={() => nextStep(4)}/>
        },
        {
            step: 4,
            component: <Step5 nextStep={() => nextStep(5)}/>
        },
        {
            step: 5,
            component: <Step6 nextStep={() => nextStep(6)}/>
        },
        {
            step: 6,
            component: <Step7 nextStep={() => nextStep(7)}/>
        },
        {
            step: 7,
            component: <Step8 nextStep={() => nextStep(8)}/>
        },
        {
            step: 8,
            component: <Step9 nextStep={() => nextStep(9)}/>
        },
        {
            step: 9,
            component: <Step10 nextStep={() => nextStep(10)}/>
        },
        {
            step: 10,
            component: <Step11 nextStep={() => nextStep(11)}/>
        }
    ]

    if (token !== "demo" || isNull(currentStep)) return null;

    return (
        <div>
            {currentStep.component}
        </div>
    );
}