import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import NextStepButton from "./NextStepButton";
import isNull from "../../../mixin/isNull";
import useStore from "../../../use/useStore";
import useAlert from "../../../use/useAlert";
import enums from "../../../enum/enums";
import WarningIcon from "../../util/icon/WarningIcon";
import PhoneIcon from "../../util/icon/PhoneIcon";
import useApi from "../../../api/useApi";
import ScreenShotViewer from "./screen-shot/ScreenShotViewer";

function StepAppointment() {
    const api = useApi();
    const store = useStore();
    const alert = useAlert();
    const appointmentInput = useRef();
    const client = useSelector(state => state.app.client);
    const qualityInspection = useSelector(state => state.app.qualityInspection);
    const [buttonLoading, setButtonLoading] = useState(false);
    const alertInterval = useRef();

    useEffect(() => {
        launchAlertMessage();
    }, []);

    const launchAlertMessage = () => {

        alertInterval.current = setInterval(() => {
            const appointmentDelayMessage = document.getElementById("appointment-delay-message");

            if (appointmentDelayMessage.style.background === "") {
                appointmentDelayMessage.style.background = "red";
                appointmentDelayMessage.style.color = "white";
                appointmentDelayMessage.style.borderRadius = "7px"
            } else {
                appointmentDelayMessage.style.background = "";
                appointmentDelayMessage.style.color = "#373737";
            }

        }, 1000);

        setTimeout(() => {
            clearInterval(alertInterval.current);
            const appointmentDelayMessage = document.getElementById("appointment-delay-message");

            appointmentDelayMessage.style.background = "";
            appointmentDelayMessage.style.color = "red";
            appointmentDelayMessage.style.fontWeight = "bold";
        }, 4000)
    }

    const nextStep = async () => {
        if (appointmentInput.current.value === "") {
            alert.launch("Merci de renseigner la date de RDV", "warning");
            return;
        }

        setButtonLoading(true);

        const isSuccess = await api.qualityInspection.nextStep({
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_APPOINTMENT
            },
            intervention: {
                appointment: new Date(appointmentInput.current.value).toISOString(),
            }
        });

        if (isSuccess) {
            await store.init();
            return;
        }

        setButtonLoading(false);
        alert.launch("Une erreur s'est produite", "error");
    };

    const clientNotReply = async () => {
        await api.qualityInspection.patchClientNotReply(true);

        alert.launch("OK", "success");
    }

    const clientContactedByCompany = phone => async () => {
        await api.qualityInspection.patchClientContactedByCompany(true);
        document.location.href = `tel:${phone}`;
        store.init();
    }

    return (
        <div>
            <div className="my-5 p-2 flex-column">
                <p id="appointment-delay-message"
                   className="text-center p-1"
                >
                    Définissez avec le sociétaire le rendez-vous sous 15 minutes maximum.
                </p>
                <div className="flex flex-col my-2">
                    <button className="w-full py-2 text-blue-1 ">
                        <a className="flex justify-center" onClick={clientContactedByCompany(client.phone_1)}>
                            <PhoneIcon size={6}/>
                            <p>
                                Appelez <b>{client.name}</b>
                            </p>
                        </a>
                    </button>
                    {
                        !isNull(client.phone_2) && client.phone_2 !== client.phone_1
                            ? (
                                <button className="w-full py-2 text-blue-1 ">
                                    <a className="flex justify-center" onClick={clientContactedByCompany(client.phone_2)}>
                                        <PhoneIcon/>
                                        <p>
                                            Deuxième numéro de téléphone
                                        </p>
                                    </a>
                                </button>
                            )
                            : null
                    }
                </div>

                <div className="mt-3">
                    <div className="flex">
                        <WarningIcon size={8}/>
                        <p className="ml-2 text-center">
                            L'intervention doit être <b>réalisée dans les 3 heures</b> après l'acceptation de la
                            mission. <br/>
                            <br/>
                        </p>
                    </div>

                    <div className="mt-5">
                        <hr/>
                        Renseignez ici la date et heure du rendez-vous. <br/>

                        <div className="flex justify-center mt-5">
                            <div className="my-2 bg-blue-1 p-3 rounded-lg text-white" id="demo-appointment-input">
                                <div className="justify-center">
                                    <input
                                        type="datetime-local"
                                        className="w-full m-0 bg-blue-1"
                                        ref={appointmentInput}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            !isNull(qualityInspection) && qualityInspection.client_contacted_by_company
                                ? (
                                    <div className="flex justify-center mt-5">
                                        <button className="bg-blue-1 text-white py-5 px-2 rounded" onClick={clientNotReply}>
                                            Cliquez ici si le sociétaire ne répond pas
                                        </button>
                                    </div>
                                )
                                : null
                        }
                    </div>

                    <div className="mt-5">
                        <hr/>
                        <div className="pt-1">
                            <ScreenShotViewer/>
                        </div>
                    </div>
                </div>

            </div>
            <NextStepButton title={"VALIDER LE RDV"} callback={nextStep} loading={buttonLoading} style={"font-bold"}/>
        </div>
    );
}

export default StepAppointment;
