import axios from "axios";
import {useParams} from "react-router";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async getScreenShots() {
            if (token === "demo") return null;

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/screen_shots/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async getScreenShotContent(fileId) {
            if (token === "demo") return null;
            
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/screen_shots/by/token/${token}/by/file_id/${fileId}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
    }
}