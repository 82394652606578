import specificity from "../../../../specificity/specificity";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import useEventDispatcher from "../../../../use/useEventDispatcher";
import event from "../../../../event/event";
import isNull from "../../../../mixin/isNull";
import useApi from "../../../../api/useApi";

/**
 * @param onItemSelected function (name, description, price, quantity, standardizedItemId = null)
 * @param {array} quotationItems
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ({onItemSelected, quotationItems}) {
    const api = useApi();
    const eventDispatcher = useEventDispatcher();
    const liClass = 'text-sm hover:bg-blue-200 text-center w-full py-2 px-1 border-b border-b-gray-300 my-auto cursor-pointer';
    const order = useSelector(state => state.app.order);
    const isQuotationStandardized = specificity.isQuotationStandardized(order.prescriber_id);
    const [standardizedItems, setStandardizedItems] = useState([]);
    const [items, setItems] = useState(standardizedItems);
    const [itemId, setItemId] = useState(null);
    const [search, setSearch] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    useEffect(async () => {
        if (!isQuotationStandardized) return;

        const standardizedItems = await api.standardizedItem.getAllStandardizedItems();
        setStandardizedItems(standardizedItems);
    }, []);

    useEffect(async () => {
        const standardizedItems = await api.standardizedItem.getAllStandardizedItems();
        setStandardizedItems(standardizedItems);

        // Remove StandardizedItem of choices if present in QuotationItems
        const result = standardizedItems.filter(
            standardizedItem => isNull(quotationItems.find(
                quotationItem => quotationItem.standardized_item_id === standardizedItem.id
            ))
        );

        setStandardizedItems(result);
    }, [quotationItems]);

    useEffect(() => {
        if (isNull(itemId)) {
            return;
        }

        if (!isNull(standardizedItems)) {
            const standardizedItem = standardizedItems.find(standardizedItem => standardizedItem.id === itemId);

            if (!isNull(standardizedItem)) {
                const quotationItem = {
                    name: standardizedItem.name,
                    description: null,
                    price: standardizedItem.price,
                    quantity: 1,
                    standardizedItemId: standardizedItem.id
                }

                onItemSelected(quotationItem);
            }

            setItemId(null);
        }
    }, [itemId]);

    const filter = (search) => {
        if (search.length === 0) {
            setItems(standardizedItems);
            return;
        }

        const removeAccent = string => {
            return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        const compareString = (string1, string2) => {
            const regex = /(?!le|la|et|un|en|de|une|des|les|dans)([0-9]+|[A-zÀ-ÖØ-öø-ÿ0-9/.-]{2,})/;
            const standardizedItemWords = string1.split(" ").filter(word => word.match(regex));

            for (let i = 0; i < standardizedItemWords.length; i++) {
                let input = string2;

                if (standardizedItemWords[i].startsWith(input)) {
                    return true;
                }

                for (let j = 0; j < standardizedItemWords[i].length; j++) {
                    if (input[j] === standardizedItemWords[i][j]) {
                        continue;
                    }

                    if (j + 1 === standardizedItemWords[i].length) {
                        continue;
                    }

                    // vérification de la section moins une lettre
                    if (input[j + 1] === standardizedItemWords[i][j]) {
                        input = input.substring(0, j) + input.substring(j + 1);
                        j--;
                        continue;
                    }

                    // vérification de la section plus une lettre
                    if (input[j] === standardizedItemWords[i][j + 1]) {
                        input = input.substring(0, j) + standardizedItemWords[i][j] + input.substring(j);
                        j--;
                        continue;
                    }
                    break;
                }

                if (standardizedItemWords[i].includes(input) || input.includes(standardizedItemWords[i])) {
                    return true;
                }

                let inputSize = string2.length;
                let standardizedItemWordSize = standardizedItemWords[i].length;

                let numberDifference = 0;
                for (let j = 0; j < (standardizedItemWordSize > inputSize ? standardizedItemWordSize : inputSize); j++) {
                    if (standardizedItemWords[i][j] !== string2[j]) {
                        numberDifference++;
                    }
                }
                if (numberDifference <= 1) {
                    return true;
                }
            }

            return false;
        }

        setItems(standardizedItems.filter(item => {
            const searchWords = removeAccent(search).split(" ");

            for (let i = 0; i < searchWords.length; i++) {
                if (searchWords[i] === "") {
                    continue;
                }

                if (compareString(removeAccent(item.name), searchWords[i])) {
                    return true;
                }
            }

            return false;
        }));
    }

    const filterV2 = (search) => {
        if (search.length === 0) {
            setItems(standardizedItems);
            return;
        }

        const removeAccent = string => {
            return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        const compareString = (string1, string2) => {
            const excludeWord = [
                "le",
                "la",
                "et",
                "un",
                "en",
                "de",
                "une",
                "des",
                "les",
                "dans",
                "du"
            ];

            const string2Array = string2.split(" ");

            for (let i = 0; i < string2Array.length; i++) {
                if (excludeWord.includes(string2Array[i])) {
                    continue;
                }

                if (!string1.includes(string2Array[i])) {
                    return false;
                }
            }

            return true;
        }

        setItems(standardizedItems.filter(item => compareString(removeAccent(item.name), removeAccent(search))));
    }

    const change = (id) => {
        setItemId(id);
        setShowOptions(false);
        setSearch('');
    }

    useEffect(() => {
        setItems(standardizedItems)
    }, [standardizedItems]);

    if (!isQuotationStandardized) return null;


    return (
        <div className="flex justify-center" onMouseLeave={() => setShowOptions(false)}>
            <div className="mb-3 w-full">
                <input
                    type="text"
                    onChange={(event) => {
                        filterV2(event.target.value)
                        setSearch(event.target.value);
                    }}
                    onClick={() => {
                        setShowOptions(true);
                        const element = document.getElementById("search-field");
                        element.scrollIntoView({
                            block: "start"
                        })
                    }}
                    id="search-field"
                    value={search}
                    placeholder="Parcourir le catalogue"
                    autoComplete="off"
                    className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
                {
                    showOptions
                        ? (
                            <ul className="bg-gray-100 h-56 border items-center mb-2 min-h-0 shadow-lg overflow-hidden overflow-y-scroll"
                                id="select-standardized-item-option"
                                style={{'width': '100% !important'}}>
                                {
                                    items.length > 0
                                        ?
                                        items.map((item, index) => (
                                            <li key={index} className={liClass}
                                                onClick={() => change(item.id, item.name)}>{item.name}</li>
                                        ))
                                        : null
                                }

                                <li
                                    className={`${liClass} bg-blue-1 text-white`}
                                    onClick={() => eventDispatcher.launcher(event.SHOW_CREATE_QUOTATION_ITEM, {
                                        search: search
                                    })}
                                >
                                    Ajouter une pièce hors catalogue
                                </li>


                            </ul>
                        )
                        : null
                }

            </div>
        </div>
    );
}
