import React, {useEffect, useRef, useState} from "react";
import mixin from "../../../mixin/mixin";
import ListQuotationItem from "./quotation/ListQuotationItem";
import UpdateQuotationItem from "./quotation/UpdateQuotationItem";
import CreateQuotationItem from "./quotation/CreateQuotationItem";
import VatSelection from "./quotation/VatSelection";
import NextStepButton from "./NextStepButton";
import {useDispatch, useSelector} from "react-redux";
import {openConfirmPassword} from "../../../store/util";
import StandardizedItemSelect from "./quotation/StandardizedItemSelect";
import specificity from "../../../specificity/specificity";
import PlusIcon from "../../util/icon/PlusIcon";
import useEventDispatcher from "../../../use/useEventDispatcher";
import event from "../../../event/event";
import useAlert from "../../../use/useAlert";
import useStore from "../../../use/useStore";
import enums from "../../../enum/enums";
import InfoIcon from "../../util/icon/InfoIcon";
import useApi from "../../../api/useApi";
import isNull from "../../../mixin/isNull";

const PASSWORD = "123";

function StepQuotation() {
    const api = useApi();
    const store = useStore();
    const alert = useAlert();
    const eventDispatcher = useEventDispatcher();
    const dispatch = useDispatch();
    const order = useSelector(state => state.app.order);
    const intervention = useSelector(state => state.app.intervention);
    const [quotationItems, setQuotationItems] = useState([]);
    const [showFormCreate, setShowFormCreate] = useState(null);
    const [updateByReactId, setUpdateByReactId] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [vat, setVat] = useState(null);
    const shiftingItemName = "Déplacement supplémentaire";
    const [shiftingItemPrice, setShiftingPrice] = useState(order.sell_price_shifting);
    const mainWorkItemName = "Main d'oeuvre supplémentaire";
    const [mainWorkItemPrice, setMainWorkItemPrice] = useState(order.sell_price_main_work);
    const secondAppointmentInput = useRef();
    const companyNumberInput = useRef();
    const clientEmailInput = useRef();
    const clientPhoneInput = useRef();

    useEffect(() => {
        api.suggestion.getSupportedPrice()
            .then(response => {
                setQuotationItems(response);
            });
    }, []);

    useEffect(() => {
        const recipient = e => {
            setShowFormCreate(e.data.search);
        }

        eventDispatcher.subscribe(event.SHOW_CREATE_QUOTATION_ITEM, recipient);

        return () => eventDispatcher.unsubscribe(event.SHOW_CREATE_QUOTATION_ITEM, recipient);
    }, []);

    const getVat = (quotationVat) => {
        setVat(quotationVat);
    }

    const requestActionController = (action, reactId) => {
        switch (action) {
            case 'REMOVE':
                removeItem(reactId);
                break;
            case 'UPDATE':
                if (specificity.isQuotationStandardized(order.prescriber_id) && quotationItems.find(item => item.reactId === reactId).default_item_id !== null) {
                    return;
                }

                setUpdateByReactId(reactId);
                break;
        }
    }

    const back = () => {
        setShowFormCreate(null);
        setUpdateByReactId(null);
    }

    const removeItem = reactId => {
        setQuotationItems(quotationItems => quotationItems.filter(quotationItem => quotationItem.reactId !== reactId));
    }

    const updateItem = item => {
        const quotationItem = quotationItems.find((item) => item.reactId === updateByReactId);
        quotationItem.name = item.name !== undefined ? item.name : quotationItem.name;
        quotationItem.description = item.description !== undefined ? item.description : quotationItem.description;
        quotationItem.price = item.price !== undefined ? item.price : quotationItem.price;
        quotationItem.quantity = item.quantity;

        setUpdateByReactId(null);
        setShowFormCreate(null);
    }

    const createItem = quotationItem => {
        quotationItems.push({
            reactId: mixin.uuid(),
            name: quotationItem.name,
            description: quotationItem.description,
            price: quotationItem.price,
            quantity: quotationItem.quantity,
            default_item_id: null,
            can_be_increased: true,
            standardized_item_id: quotationItem.standardizedItemId !== undefined ? quotationItem.standardizedItemId : null,
            is_managed: quotationItem.is_managed !== undefined ? quotationItem.is_managed : quotationItem.is_managed
        });
        setQuotationItems([...quotationItems])

        setShowFormCreate(null);
    }

    const addShifting = () => {
        const shifting = {
            name: shiftingItemName,
            description: null,
            price: shiftingItemPrice,
            quantity: 1,
            can_be_increased: true,
            default_item_id: null,
            standardized_item_id: null,
            is_managed: true
        }

        createItem(shifting);
    }

    const addMainWork = () => {
        if (quotationItems.find(quotationItem => quotationItem.name === mainWorkItemName) !== undefined) {
            return;
        }

        const mainWork = {
            name: mainWorkItemName,
            description: null,
            price: mainWorkItemPrice,
            quantity: 1,
            can_be_increased: true,
            default_item_id: null,
            standardized_item_id: null,
            is_managed: true
        }

        createItem(mainWork);
    }

    const confirm = () => {
        const clientPhone = clientPhoneInput.current.value;

        if (clientEmailInput.current.value === "") {
            alert.launch("Veuillez renseigner l'email du sociétaire", "warning");
            document.getElementById("client-email").focus();
            return;
        }

        if (clientPhone === ""
            || clientPhone.split(" ").join("") === intervention.artisan_phone_mobile.replace("+33", "0")
            || clientPhone.split(" ").join("") === intervention.company_phone_mobile.replace("+33", "0")) {
            alert.launch("Veuillez renseigner le téléphone du signataire", "warning");
            document.getElementById("client-phone").focus();
            return;
        }

        if (secondAppointmentInput.current.value == "" || isNull(secondAppointmentInput.current.value)) {
            alert.launch("Veuillez renseigner la date d'intervention", "warning");
            return;
        }

        dispatch(openConfirmPassword({
            title: "Vous allez passer à l'étape suivante",
            description: null,
            callback: [
                nextStep
            ],
            password: PASSWORD
        }));
    }


    const nextStep = async isConfirm => {
        if (!isConfirm) {
            return;
        }

        await api.client.updateEmail(clientEmailInput.current.value);
        await api.client.updatePhone1(clientPhoneInput.current.value);

        setButtonLoading(true);
        const isSuccess = await api.qualityInspection.nextStep({
            quotation: {
                vat: vat.vat,
                company_number: companyNumberInput.current.value
            },
            quotation_item: quotationItems,
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_QUOTATION
            },
            intervention: {
                second_appointment: new Date(secondAppointmentInput.current.value).toISOString()
            }
        });

        if (isSuccess) {
            await store.init();
            return;
        }

        setButtonLoading(false);
        alert.launch("Une erreur s'est produite", "error");

    }

    return (
        <div>
            <div className={'mt-10'}>
                {getInfo()}
            </div>
            <div className={'my-5 p-2 flex-column'}>
                {
                    showFormCreate === null && updateByReactId === null
                        ? (
                            <div>
                                <ListQuotationItem quotationItems={quotationItems}
                                                   requestActionController={requestActionController}/>

                                <StandardizedItemSelect onItemSelected={createItem}
                                                        quotationItems={quotationItems}/>
                                <div className={'flex flex-col my-2 mt-5 mb-7'}>
                                    <button
                                        id="add-shifting-button"
                                        className={`bg-blue-600 text-white py-2 my-2 flex justify-center w-full ${quotationItems.find(quotationItem => quotationItem.name === shiftingItemName) !== undefined ? "hidden" : null}`}
                                        onClick={addShifting}
                                    >
                                        Déplacement supplémentaire
                                        <div className="my-auto ml-2">
                                            <PlusIcon size={6}/>
                                        </div>
                                    </button>
                                    <button
                                        id="add-main-work-button"
                                        className={`bg-blue-600 text-white py-2 my-2 flex justify-center w-full ${quotationItems.find(quotationItem => quotationItem.name === mainWorkItemName) !== undefined ? "hidden" : null}`}
                                        onClick={addMainWork}
                                    >
                                        Main d'oeuvre supplémentaire
                                        <div className="my-auto ml-2">
                                            <PlusIcon size={6}/>
                                        </div>
                                    </button>
                                </div>

                                <VatSelection callBack={getVat}/>

                                <div className="input-group mt-5">
                                    <div>Quelle est la date prévue pour l'intervention ? (maintenant ou plannifiée)</div>
                                    <div className="flex justify-center mt-1">
                                        <div>
                                            <input
                                                type="datetime-local"
                                                className="w-full m-0 bg-blue-1 text-white"
                                                ref={secondAppointmentInput}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group mt-5">
                                    <label>Renseignez le numéro de votre devis ?</label>
                                    <input
                                        className="input-text"
                                        ref={companyNumberInput}
                                        placeholder="Numéro de votre devis"
                                    />
                                </div>

                                <div className="input-group mt-5">
                                    <label>Veuillez renseigner l'email du sociétaire</label>
                                    <input
                                        id="client-email"
                                        className="input-text"
                                        ref={clientEmailInput}
                                        placeholder="Email du sociétaire"
                                    />
                                </div>

                                <div className="input-group mt-5">
                                    <label>Renseigner le numéro de téléphone <strong>portable</strong> du
                                        signataire</label>
                                    <input
                                        id="client-phone"
                                        className="input-text"
                                        ref={clientPhoneInput}
                                        placeholder="Téléphone portable du signataire"
                                    />
                                </div>
                            </div>
                        )
                        : null
                }

                {getUpdateQuotationItem(back, updateItem, quotationItems, updateByReactId, showFormCreate)}

                {getCreateQuotationItem(back, createItem, updateByReactId, showFormCreate)}

            </div>
            {getNextStepButton(showFormCreate, updateByReactId, confirm, buttonLoading)}
        </div>
    );
}

function getInfo() {
    return null;

    return (
        <div className={'my-2 p-2 flex bg-blue-0 text-blue-1 rounded items-center'}>
            <InfoIcon/>
            <div className="leading-tight">
                Attention, il s'agit d'une intervention d'urgence avec mesures conservatoires
            </div>
        </div>
    );
}

function getCreateQuotationItem(back, createItem, updateByReactId, showFormCreate) {
    if (updateByReactId !== null || showFormCreate === null) {
        return null;
    }

    return (
        <CreateQuotationItem backCallback={back} createCallback={createItem} defaultName={showFormCreate}/>
    );
}

function getUpdateQuotationItem(back, updateItem, quotationItems, updateByReactId, showFormCreate) {
    if (updateByReactId === null || showFormCreate !== null) {
        return null;
    }

    return (
        <UpdateQuotationItem backCallback={back} updateCallback={updateItem}
                             quotationItem={quotationItems.find((item) => item.reactId === updateByReactId)}/>
    );
}

function getNextStepButton(showFormCreate, updateByReactId, confirm, loading) {
    if (showFormCreate !== null || updateByReactId !== null) {
        return;
    }

    return (
        <NextStepButton title={'VALIDER'} callback={confirm} loading={loading}/>
    );
}

export default StepQuotation;
