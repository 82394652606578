import {useParams} from "react-router";
import axios from "axios";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async getUpsellComment() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/upsell_comments/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        }
    }
}