import {useEffect, useState} from "react";
import useScreenShot from "../../../../api/useScreenShot";
import isNull from "../../../../mixin/isNull";
import ChevronLeftIcon from "../../../util/icon/ChevronLeftIcon";
import ChevronRightIcon from "../../../util/icon/ChevronRightIcon";

export default function () {
    const screenShotApi = useScreenShot();
    const [screenShots, setScreenShots] = useState([]);
    const [current, setCurrent] = useState(null);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (isNull(current) || current === -1 || screenShots.length === 0) return;

        fetchContent(current);
    }, [current, screenShots.length]);

    const fetch = async () => {
        const response = await screenShotApi.getScreenShots();

        if (isNull(response) || response.length === 0) {
            setCurrent(-1)
            return;
        }

        setScreenShots(response);
        setCurrent(response[0].id);
    }

    const fetchContent = async id => {
        if (!isNull(getCurrentFile())) return;

        const result = [...screenShots];

        const response = await screenShotApi.getScreenShotContent(result.find(r => r.id == id).file_id);

        result.find(r => r.id == id).file = response;


        setScreenShots(result);
    }

    const getCurrentFile = () => {
        return screenShots.find(r => r.id == current)?.file;
    }

    const next = () => {
        const index = screenShots.findIndex(r => r.id == current);

        if (screenShots.length - 1 > index) {
            setCurrent(screenShots[index + 1].id);
        } else {
            setCurrent(screenShots[0].id);
        }
    }

    const prev = () => {
        const index = screenShots.findIndex(r => r.id == current);

        if (index > 0) {
            setCurrent(screenShots[index - 1].id);
        } else {
            setCurrent(screenShots[screenShots.length - 1].id);
        }
    }

    return (
        <div>
            <div className="text-left flex ">
                <div className="rounded-full px-2 bg-orange-600 text-white mr-1 text-xs my-auto">nouveau</div>
                Photos avant intervention :
            </div>
            <div className="mb-5 text-xs italic">
                Retrouvez les tout au long de l'intervention dans le menu
            </div>

            {
                current === -1
                    ? <div className="text-center">Aucune photo disponible</div>
                    : null
            }

            {
                isNull(current)
                    ? <div className="text-center">Chargement des photos...</div>
                    : null
            }

            {
                current !== -1 && !isNull(current) && isNull(getCurrentFile())
                    ? <div className="text-center">Chargement de la photo...</div>
                    : null
            }

            {
                !isNull(current) && !isNull(getCurrentFile())
                    ? (
                        <div className="relative">
                            <div className="absolute top-[40%] text-blue-1" onClick={prev}>
                                <ChevronLeftIcon size={20}/>
                            </div>
                            <img src={`data:${getCurrentFile().content_type};base64,${getCurrentFile().base64}`}/>
                            <div className="absolute top-[40%] right-0 text-blue-1" onClick={next}>
                                <ChevronRightIcon size={20}/>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
    );
}