import useSuggestion from "./useSuggestion";
import useWork from "./useWork";
import useQualityInspection from "./useQualityInspection";
import useRequestedDocument from "./useRequestedDocument";
import useStandardizedItem from "./useStandardizedItem";
import useIntervention from "./useIntervention";
import useClient from "./useClient";
import useArtisan from "./useArtisan";
import useQuotation from "./useQuotation";
import useQuotationItem from "./useQuotationItem";
import useUpsellComment from "./useUpsellComment";

export default function () {
    const suggestion = useSuggestion();
    const work = useWork();
    const qualityInspection = useQualityInspection();
    const requestedDocument = useRequestedDocument();
    const standardizedItem = useStandardizedItem();
    const intervention = useIntervention();
    const client = useClient();
    const quotation = useQuotation();
    const quotationItems = useQuotationItem();
    const artisan = useArtisan();
    const upsellComment = useUpsellComment();

    return {
        suggestion,
        work,
        qualityInspection,
        requestedDocument,
        standardizedItem,
        intervention,
        client,
        artisan,
        quotation,
        quotationItems,
        upsellComment
    }
}